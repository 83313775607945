import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";

const ACTION_BAR_HEIGHT = "80px";

const style = {
  actionBarFull: {
    maxWidth: "90vw",
    backgroundColor: "#1d1e1f",
    border: "solid 2px #282828",
    height: ACTION_BAR_HEIGHT,
    display: "flex",
    alignItems: "center",
    padding: "0 1.2rem",
    borderRadius: "20px",
    marginBottom: "20px",
    zIndex: 4,
  },
  actionBarMin: {
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 4,
  },
};

function ActiveCallActionBar({
  minimized,
  setMinimized,
  activeCall,
  handleHangup,
  hideDefaultActions,
  children,
}) {
  const disconnecting = activeCall?.state === "disconnecting";

  return (
    <div style={minimized ? style.actionBarMin : style.actionBarFull}>
      {!minimized && <>{children}</>}

      {!hideDefaultActions && (
        <>
          <MinMaxButton minimized={minimized} setMinimized={setMinimized} />
          <HangupButton
            activeCall={activeCall}
            minimized={minimized}
            disconnecting={disconnecting}
            handleHangup={handleHangup}
          />
        </>
      )}
    </div>
  );
}

function MinMaxButton({ minimized, setMinimized }) {
  return (
    <Popup
      content={minimized ? "Maximize" : "Minimize"}
      position={minimized ? "top right" : "top center"}
      trigger={
        <Button color="black" onClick={() => setMinimized(!minimized)} icon>
          <Icon name={minimized ? "expand" : "window minimize"} />
        </Button>
      }
    />
  );
}

function HangupButton({ activeCall, minimized, disconnecting, handleHangup }) {
  return (
    <Button
      loading={disconnecting}
      disabled={!activeCall || disconnecting}
      color="red"
      onClick={handleHangup}
      icon={minimized}
    >
      <Icon name="user close" />
      {!minimized && "End Call"}
    </Button>
  );
}

export default ActiveCallActionBar;
